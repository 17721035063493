export const GET_ITEM_CATEGORIES_PROCESS = 'GET_ITEM_CATEGORIES_PROCESS';
export const GET_ITEM_CATEGORIES_START = 'GET_ITEM_CATEGORIES_START';
export const GET_ITEM_CATEGORIES_SUCCESS = 'GET_ITEM_CATEGORIES_SUCCESS';

export const GET_BUSINESS_SUBCATEGORY_FILTERS_PROCESS = 'GET_BUSINESS_SUBCATEGORY_FILTERS_PROCESS';
export const GET_BUSINESS_SUBCATEGORY_FILTERS_START = 'GET_BUSINESS_SUBCATEGORY_FILTERS_START';
export const GET_BUSINESS_SUBCATEGORY_FILTERS_SUCCESS = 'GET_BUSINESS_SUBCATEGORY_FILTERS_SUCCESS';

export const GET_BUSINESS_SUBCATEGORIES_PROCESS = 'GET_BUSINESS_SUBCATEGORIES_PROCESS';
export const GET_BUSINESS_SUBCATEGORIES_START = 'GET_BUSINESS_SUBCATEGORIES_START';
export const GET_BUSINESS_SUBCATEGORIES_SUCCESS = 'GET_BUSINESS_SUBCATEGORIES_SUCCESS';

export const GET_BUSINESS_CATEGORIES_PROCESS = 'GET_BUSINESS_CATEGORIES_PROCESS';
export const GET_BUSINESS_CATEGORIES_START = 'GET_BUSINESS_CATEGORIES_START';
export const GET_BUSINESS_CATEGORIES_SUCCESS = 'GET_BUSINESS_CATEGORIES_SUCCESS';
export const CLEAR_BUSINESS_CATEGORIES = 'CLEAR_BUSINESS_CATEGORIES';
export const GET_SEARCH_CATEGORIES_PROCESS = 'GET_SEARCH_CATEGORIES_PROCESS';
export const GET_SEARCH_CATEGORIES_START = 'GET_SEARCH_CATEGORIES_START';

export const GET_TOP_LEVEL_GROUPS_START = 'GET_TOP_LEVEL_GROUPS_START';
export const GET_TOP_LEVEL_GROUPS_SUCCESS = 'GET_TOP_LEVEL_GROUPS_SUCCESS';
export const GET_TOP_LEVEL_GROUPS_PROCESS = 'GET_TOP_LEVEL_GROUPS_PROCESS';

export const GET_INTERESTS_CATEGORY_PROCESS = 'GET_INTERESTS_CATEGORY_PROCESS';
export const GET_INTERESTS_CATEGORY_START = 'GET_INTERESTS_CATEGORY_START';
export const GET_INTERESTS_CATEGORY_SUCCESS = 'GET_INTERESTS_CATEGORY_SUCCESS';

export const GET_DESTINATION_CATEGORIES_PROCESS = 'GET_DESTINATION_CATEGORIES_PROCESS';
export const GET_DESTINATION_CATEGORIES_START = 'GET_DESTINATION_CATEGORIES_START';
export const GET_DESTINATION_CATEGORIES_SUCCESS = 'GET_DESTINATION_CATEGORIES_SUCCESS';

export const GET_BUSINESS_CATEGORIES_STACK_NEXT_PROCESS =
    'GET_BUSINESS_CATEGORIES_STACK_NEXT_PROCESS';
export const GET_BUSINESS_CATEGORIES_STACK_NEXT_START = 'GET_BUSINESS_CATEGORIES_STACK_NEXT_START';

export const GET_BUSINESS_CATEGORIES_STACK_INITIALIZE_PROCESS =
    'GET_BUSINESS_CATEGORIES_STACK_INITIALIZE_PROCESS';
export const GET_BUSINESS_CATEGORIES_STACK_INITIALIZE_START = 'GET_BUSINESS_CATEGORIES_STACK_INITIALIZE_START';
export const GET_BUSINESS_CATEGORIES_STACK_INITIALIZE_SUCCESS = 'GET_BUSINESS_CATEGORIES_STACK_INITIALIZE_SUCCESS';

export const GET_BUSINESS_CATEGORIES_STACK_RESET_PROCESS =
    'GET_BUSINESS_CATEGORIES_STACK_RESET_PROCESS';
export const GET_BUSINESS_CATEGORIES_STACK_RESET_START =
    'GET_BUSINESS_CATEGORIES_STACK_RESET_START';

export const GET_BUSINESS_CATEGORIES_STACK_SUCCESS = 'GET_BUSINESS_CATEGORIES_STACK_SUCCESS';
