import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ProductCategoriesFormFields from 'screens/BusinessForm/ProductCategoriesForm/ProductCategoriesFormFields';
import {
    SET_PRODUCT_CATEGORY_START,
    SET_PRODUCT_TO_EDIT_PROPERTY_START,
} from 'stores/redux/actions/EditProductActions';
import { productToEditSelector } from 'stores/redux/selectors/productSelectors';

const ModalEditProductCategories = () => {
    const dispatch = useDispatch();
    const [selectedFilters, setSelectedFilters] = useState([]);
    const product = useSelector(productToEditSelector);

    useEffect(() => {
        if (!product?.mapping?.tags?.length) {
            return;
        }

        setSelectedFilters((product?.mapping?.tags || []).map(tag => tag.id));
    }, [dispatch]);

    const handleUnsavedChanges = () => {
        dispatch({
            type: SET_PRODUCT_TO_EDIT_PROPERTY_START,
            payload: {
                hasUnsavedChanges: true,
            },
        });
    };

    const onSubmit = (values) => {
        dispatch({
            type: SET_PRODUCT_CATEGORY_START,
            payload: values,
        });

        handleUnsavedChanges();
    };

    return (
        <div>
            <div>
                <ProductCategoriesFormFields
                    onSelectedFiltersChange={setSelectedFilters}
                    selectedFilters={selectedFilters}
                    onSubmit={onSubmit}
                />
            </div>
        </div>
    );
};

export default ModalEditProductCategories;
