import { cloneDeep, difference, get, remove } from 'lodash';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import update from 'immutability-helper';
import { businessIdSelector } from 'stores/redux/selectors/businessFormSelectors';
import productsAndServicesUtils from 'common/utils/productsAndServicesUtils';
import { SET_BUSINESS_FORM_DATA_START } from 'stores/redux/actions/BusinessFormActions';
import categoriesAndFiltersUtils from 'common/utils/categoriesAndFiltersUtils';
import {
    GetBusinessSectionItems,
    PutBusinessSectionItems,
    PutBusinessSections,
    createEntity,
    editEntity,
} from 'api';
import {
    ADD_PRODUCT_CATEGORY_PROCESS,
    ADD_PRODUCT_CATEGORY_START,
    ADD_PRODUCT_CATEGORY_SUCCESS,
    ADD_PRODUCT_PROCESS,
    ADD_PRODUCT_START,
    ADD_PRODUCT_SUBCATEGORY_PROCESS,
    ADD_PRODUCT_SUBCATEGORY_START,
    ADD_PRODUCT_SUBCATEGORY_SUCCESS,
    DELETE_PRODUCT_CATEGORY_PROCESS,
    DELETE_PRODUCT_CATEGORY_START,
    DELETE_PRODUCT_FROM_SECTION_START,
    DELETE_PRODUCT_PHOTO_PROCESS,
    DELETE_PRODUCT_PHOTO_START,
    DELETE_PRODUCT_PHOTO_SUCCESS,
    DELETE_PRODUCT_SUBCATEGORY_PROCESS,
    DELETE_PRODUCT_SUBCATEGORY_START,
    DELETE_SELECTED_PRODUCT_CATEGORY_PROCESS,
    DELETE_SELECTED_PRODUCT_CATEGORY_START,
    DROP_PRODUCT_TO_SECTION_PROCESS,
    DROP_PRODUCT_TO_SECTION_START,
    POST_SAVE_PRODUCT_PROCESS,
    POST_SAVE_PRODUCT_START,
    REMOVE_PRODUCT_FROM_SECTION_PROCESS,
    REMOVE_PRODUCT_FROM_SECTION_START,
    SAVE_PRODUCTS_BULK_PROCESS,
    SAVE_PRODUCTS_BULK_START,
    SET_PRODUCT_CATEGORY_PROCESS,
    SET_PRODUCT_CATEGORY_START,
    SET_PRODUCT_CATEGORY_SUCCESS,
    SET_PRODUCT_PHOTO_PROCESS,
    SET_PRODUCT_PHOTO_START,
    SET_PRODUCT_PHOTO_SUCCESS,
    SET_PRODUCT_SECTIONS_PROCESS,
    SET_PRODUCT_SECTIONS_START,
    SET_PRODUCT_SECTIONS_SUCCESS,
    SET_PRODUCT_TO_EDIT_PROCESS,
    SET_PRODUCT_TO_EDIT_PROPERTY_START,
    SET_PRODUCT_TO_EDIT_START,
    SET_PRODUCT_TO_EDIT_SUCCESS,
    SET_SELECTED_PRODUCT_CATEGORY_PROCESS,
    SET_SELECTED_PRODUCT_CATEGORY_START,
    SET_SELECTED_PRODUCT_CATEGORY_SUCCESS,
    UNSET_PRODUCT_SECTIONS_PROCESS,
    UNSET_PRODUCT_SECTIONS_START,
    UNSET_PRODUCT_SECTIONS_SUCCESS,
    UPDATE_PRODUCTS_TO_EDIT_PROCESS,
    UPDATE_PRODUCTS_TO_EDIT_START,
    UPDATE_PRODUCTS_TO_EDIT_SUCCESS,
    UPDATE_PRODUCT_SECTION_PROCESS,
    UPDATE_PRODUCT_SECTION_START,
    UPDATE_PRODUCT_SECTION_SUCCESS,
    UPDATE_SECTION_PRODUCTS_ORDER_PROCESS,
} from '../actions/EditProductActions';
import {
    GET_SECTION_PRODUCTS_START,
    UPDATE_PRODUCT_IN_ALL_SECTIONS_START,
    UPDATE_PRODUCT_IN_SECTIONS_START,
} from '../actions/SectionActions';
import genericSagaHandler from './CommonSaga';
import { getSectionProductsSaga, getSectionsSaga } from './SectionSagas';

function* setProductToEditSaga({ payload }) {
    yield genericSagaHandler(SET_PRODUCT_TO_EDIT_PROCESS, function* () {
        const { sectionProducts, selectedSection } = yield select(
            ({ SectionReducer }) => SectionReducer,
        );

        const products = get(sectionProducts, [selectedSection?.replace(/ /g, '_')]);
        let productToEdit = products?.find((product) => product._id === payload);
        // if product isn't found it means it isn't there in this section yet, we will search for it in the main group
        if (!productToEdit) {
            productToEdit = sectionProducts.undefined.find((product) => product._id === payload);
        }
        productToEdit.photos = productToEdit.photos.map((photo) => {
            return {
                ...photo,
                state: 'original',
            };
        });

        if (!productToEdit) {
            throw new Error('Product not found');
        }

        yield put({
            type: SET_PRODUCT_TO_EDIT_SUCCESS,
            payload: productToEdit,
        });
    });
}

function* setProductSectionsSaga({ payload }) {
    yield genericSagaHandler(SET_PRODUCT_SECTIONS_PROCESS, function* () {
        const { productToEdit } = yield select(({ EditProductReducer }) => EditProductReducer);

        const currentSections = [...(productToEdit.sectionsFoundIn || [])];
        currentSections.push({ _id: 'new' });
        yield put({
            type: SET_PRODUCT_SECTIONS_SUCCESS,
            payload: { sectionsFoundIn: currentSections },
        });
    });
}

function* unsetProductSectionsSaga({ payload }) {
    yield genericSagaHandler(UNSET_PRODUCT_SECTIONS_PROCESS, function* unsetProductSections() {
        const sections = yield select(({ SectionReducer }) => SectionReducer.sections);
        const sectionToRemoveFrom = sections.find((section) => section._id === payload);
        const { productToEdit } = yield select(({ EditProductReducer }) => EditProductReducer);

        const currentSections = [...(productToEdit?.sectionsFoundIn || [])];
        const indexToRemove = productToEdit.sectionsFoundIn.findIndex(
            (section) => section._id === sectionToRemoveFrom._id,
        );
        currentSections.splice(indexToRemove, 1);

        const newProduct = update(productToEdit, {
            sectionsFoundIn: {
                $set: currentSections,
            },
        });

        yield put({
            type: UNSET_PRODUCT_SECTIONS_SUCCESS,
            payload: { sectionsFoundIn: currentSections },
        });
        yield put({
            type: UPDATE_PRODUCT_IN_ALL_SECTIONS_START,
            payload: {
                newProduct,
            },
        });
    });
}

function* updateProductSectionSaga({ payload }) {
    yield genericSagaHandler(UPDATE_PRODUCT_SECTION_PROCESS, function* () {
        const { productToEdit } = yield select(({ EditProductReducer }) => EditProductReducer);
        const sections = yield select(({ SectionReducer }) => SectionReducer.sections);
        const { index, value } = payload;
        const currentSections = [...(productToEdit.sectionsFoundIn || [])];
        const newSection = sections.find((section) => section._id === value);
        currentSections[index] = { ...newSection };
        yield put({
            type: UPDATE_PRODUCT_SECTION_SUCCESS,
            payload: { sectionsFoundIn: currentSections },
        });

        const newProduct = update(productToEdit, {
            sectionsFoundIn: {
                $set: currentSections,
            },
        });

        yield put({
            type: UPDATE_PRODUCT_IN_ALL_SECTIONS_START,
            payload: {
                newProduct,
            },
        });
    });
}

function* addSubCategorySaga({ payload }) {
    yield genericSagaHandler(ADD_PRODUCT_SUBCATEGORY_PROCESS, function* () {
        const { productCategoryId, subcategoryIds, productCategoryIndex } = payload;
        const { productToEdit } = yield select(({ EditProductReducer }) => EditProductReducer);
        const newCategories = cloneDeep(productToEdit.mapping.tags);
        if (newCategories[productCategoryIndex].headers?.length === 0) {
            newCategories[productCategoryIndex].headers = [{}];
        }
        const { itemCategories } = yield select(({ CategoriesReducer }) => CategoriesReducer);
        const itemCategory = itemCategories.find(
            (itemCategory) => itemCategory.id === productCategoryId,
        );
        const subCategories = [...itemCategory.subCategories];
        newCategories[productCategoryIndex].headers[0].subcategories = subcategoryIds.map((id) => {
            const { name } = subCategories.find((sc) => sc.id === id);
            return { id, name };
        });
        yield put({
            type: ADD_PRODUCT_SUBCATEGORY_SUCCESS,
            payload: newCategories,
        });
    });
}

function* addProductCategorySaga() {
    yield genericSagaHandler(ADD_PRODUCT_CATEGORY_PROCESS, function* () {
        const { productToEdit, originalProduct } = yield select(({ EditProductReducer }) => {
            console.log(EditProductReducer);
            return EditProductReducer;
        });
        console.log(productToEdit, originalProduct);
        const categories = [...get(productToEdit, ['mapping', 'tags'], [])];
        yield put({
            type: ADD_PRODUCT_CATEGORY_SUCCESS,
            payload: categories,
        });
    });
}

function* deleteProductCategorySaga({ payload }) {
    yield genericSagaHandler(DELETE_PRODUCT_CATEGORY_PROCESS, function* () {
        const { categoryName } = payload;
        const { productToEdit } = yield select(({ EditProductReducer }) => EditProductReducer);
        const categories = [...get(productToEdit, ['mapping', 'categories'])];

        yield put({
            type: SET_PRODUCT_CATEGORY_SUCCESS,
            payload: categoriesAndFiltersUtils.removeCategoryByName({
                categories,
                name: categoryName,
            }),
        });
    });
}

function* deleteProductSubcategorySaga({ payload }) {
    yield genericSagaHandler(DELETE_PRODUCT_SUBCATEGORY_PROCESS, function* () {
        const { value } = payload;
        const { productToEdit } = yield select(({ EditProductReducer }) => EditProductReducer);
        const categories = [...get(productToEdit, ['mapping', 'categories'])];

        const selectedCategory = categories.find((cat) => cat.id === value.id);

        const currentCategory = categories.find((category) => {
            return category.id === value.id;
        });

        const selectedCategoryAndFilters = () => {
            const subcategories = [];
            selectedCategory.headers.forEach((header) => {
                subcategories.push({
                    name: header.name,
                    id: header.id,
                    subcategories: [
                        ...header.subcategories.filter((subcategory) => subcategory.selected),
                    ],
                });
            });
            return {
                headers: subcategories,
            };
        };

        if (currentCategory) {
            const currentIndex = categories.findIndex((category) => {
                return category.id === value.id;
            });
            categories[currentIndex] = {
                id: selectedCategory.id,
                name: selectedCategory.name,
                ...selectedCategoryAndFilters(),
            };
        }
        yield put({
            type: SET_PRODUCT_CATEGORY_SUCCESS,
            payload: categories,
        });
    });
}

function* setProductCategorySaga({ payload }) {
    yield genericSagaHandler(SET_PRODUCT_CATEGORY_PROCESS, function* () {
        yield put({
            type: SET_PRODUCT_CATEGORY_SUCCESS,
            payload: payload,
        });
    });
}

async function prepareProductFormData(productToEdit) {
    const { _id, name, description, price, active = true, photos = [], mapping } = productToEdit;    
    const { tags = [] } = mapping || {};
    const tagIds = tags.map(tag => tag.id);

    const formData = new FormData();
    if (_id) {
        formData.append('entity_id', _id);
    }
    formData.append('active', active);
    formData.append('name', name);
    formData.append('description', description);
    formData.append('price', price);
    formData.append('type', 'PRODUCT');
    formData.append('tags', JSON.stringify(tagIds));

    // PHOTOS UPLOAD MANAGEMENT
    // For photos upload we need to do a couple of things:
    // photosOrder - this should contain an array of {name: [filename], order: index}
    // photos - this array should only contain the already uploaded photos
    // each filename is a separate formInput with file contents.
    // Append binary files for upload
    formData.append(
        'photos',
        JSON.stringify(
            photos
                ? photos
                      .filter((photo) => photo.state === 'original')
                      .map((photo, index) => {
                          const { state, ...rest } = photo;
                          return { ...rest, order: index };
                      })
                : [],
        ),
    );
    formData.append(
        'photosOrder',
        JSON.stringify(
            photos
                ? photos.map((photo, index) => {
                      return { name: photo.name, order: index };
                  })
                : [],
        ),
    );

    photos?.forEach((photo) => {
        if (photo.state === 'new' || photo.state === 'updated') {
            formData.append(photo.name, photo);
        }
    });

    return formData;
}

function* handleSectionsUpdates(productToEdit, originalProduct) {
    const { _id } = productToEdit;
    const { _id: businessId } = yield select(({ BusinessFormReducer }) => BusinessFormReducer);
    const { selectedSection } = yield select(({ SectionReducer }) => SectionReducer);  
    const { sectionsFoundIn } = productToEdit;

    const { sectionsFoundIn: originalSectionsFoundIn } = originalProduct;

    const sectionsToDelete = difference(originalSectionsFoundIn, sectionsFoundIn);

    const sectionsToAdd = difference(sectionsFoundIn, originalSectionsFoundIn);



    for (let i = 0; i < sectionsToDelete.length; i++) {
        const sectionId = sectionsToDelete[i]._id;
        const { sections, showActiveOnly } = yield select(({ SectionReducer }) => SectionReducer);
        const section = sections.find((s) => s._id === sectionId);
        // This has to be optimized, instead of calling the API every time, I should first check if the data is there in the reducer
        const sectionProducts = yield GetBusinessSectionItems({
            businessId,
            maxCount: 1000,
            pageNum: 0,
            selectedSection: section.name,
            active: showActiveOnly,
        });
        const newSectionProducts = sectionProducts.map((sP) => sP._id);

        const productIndexInSection = newSectionProducts.findIndex(
            (productId) => productId === _id,
        );
        newSectionProducts.splice(productIndexInSection, 1);

        yield PutBusinessSectionItems({
            entityId: businessId,
            sectionId: section._id,
            items: newSectionProducts,
            active: true,
        });
    }

    for (let i = 0; i < sectionsToAdd.length; i++) {
        const sectionId = sectionsToAdd[i]._id;
        const { sections } = yield select(({ SectionReducer }) => SectionReducer);
        const  productSections  = yield select(({ SectionReducer }) => SectionReducer.sectionProducts);
        const section = sections.find((s) => s._id === sectionId);
        const newSectionProducts = productSections[sectionsToAdd[i].name];
        if(!newSectionProducts.some((p) => p._id === _id)) {
            newSectionProducts.push(productToEdit);
        }

        yield PutBusinessSectionItems({
            entityId: businessId,
            sectionId: section._id,
            items: newSectionProducts.map((p) => p._id),
            active: true,
        });
    }

    yield put({
        type: GET_SECTION_PRODUCTS_START,
        payload: {
            businessId,
            selectedSection,
            productToEditId: _id,
        },
    });
}

function* postSaveProductSaga() {
    yield genericSagaHandler(POST_SAVE_PRODUCT_PROCESS, function* () {
        const { productToEdit } = yield select(({ EditProductReducer }) => EditProductReducer);
        const { _id } = productToEdit;
        const businessId = yield select(businessIdSelector);
        const formData = yield prepareProductFormData(productToEdit);
        // const { payload } = yield EditItem(formData);
        formData.append('parent_id', businessId);
        const { payload } = yield editEntity(formData);
        yield put({
            type: UPDATE_PRODUCT_IN_SECTIONS_START,
            payload: { updatedProduct: payload },
        });

        yield handleSectionsUpdates({
            ...payload,
            sectionsFoundIn: productToEdit.sectionsFoundIn
        }, {});
        /**
         * Reset has unsaved changes upon saving product
         */
        yield put({
            type: SET_PRODUCT_TO_EDIT_PROPERTY_START,
            payload: { hasUnsavedChanges: false },
        });

        yield call(setProductToEditSaga, { payload: _id });
    });
}

function* setProductPhotoSaga({ payload }) {
    yield genericSagaHandler(SET_PRODUCT_PHOTO_PROCESS, function* () {
        const { productToEdit } = yield select(({ EditProductReducer }) => EditProductReducer);

        const { photos } = productToEdit;
        const newPhotos = [...photos];
        newPhotos.push(payload);
        yield put({
            type: SET_PRODUCT_PHOTO_SUCCESS,
            payload: newPhotos,
        });
    });
}

function* deleteProductPhotoSaga({ payload }) {
    yield genericSagaHandler(DELETE_PRODUCT_PHOTO_PROCESS, function* () {
        const {
            productToEdit: { photos },
        } = yield select(({ EditProductReducer }) => EditProductReducer);
        const newPhotos = [...photos];
        newPhotos.splice(payload, 1);
        yield put({
            type: DELETE_PRODUCT_PHOTO_SUCCESS,
            payload: newPhotos,
        });
    });
}

function* addProductSaga() {
    yield genericSagaHandler(ADD_PRODUCT_PROCESS, function* () {
        // const { selectedSection } = yield select(({ SectionReducer }) => SectionReducer);
        const { productToEdit } = yield select(({ EditProductReducer }) => EditProductReducer);
        const businessId = yield select(businessIdSelector);
        const formData = yield prepareProductFormData(productToEdit);
        formData.append('parent_id', businessId);
        // const { payload } = yield CreateItem(formData);
        const { payload } = yield createEntity(formData);
        yield handleSectionsUpdates({
            ...payload,
            sectionsFoundIn: productToEdit.sectionsFoundIn
        }, {});

        yield call(getSectionProductsSaga, {
            payload: {
                businessId,
                // selectedSection,
            },
        });
        // Comment out, stay on modal
        // yield put({
        //     type: RESET_PRODUCT_TO_EDIT_START,
        // });
    });
}

function* updateSectionProductsOrder({ payload }) {
    yield genericSagaHandler(UPDATE_SECTION_PRODUCTS_ORDER_PROCESS, function* () {
        const businessId = yield select(businessIdSelector);
        const { sections, selectedSection } = yield select(({ SectionReducer }) => SectionReducer);
        const selectedSectionObejct = sections.find((s) => s.name === selectedSection);
        yield PutBusinessSectionItems({
            entityId: businessId,
            sectionId: selectedSectionObejct._id,
            items: payload.map((p) => p?._id),
            active: true,
        });
        yield put({
            type: GET_SECTION_PRODUCTS_START,
            payload: {
                businessId,
                selectedSection,
            },
        });
    });
}

function* setSelectedProductCategorySaga({ payload }) {
    yield genericSagaHandler(SET_SELECTED_PRODUCT_CATEGORY_PROCESS, function* () {
        const { productToEdit } = yield select(({ EditProductReducer }) => EditProductReducer);

        const newCategories = [...(productToEdit.mapping.tags || [])];

        const alreadySelectedCategoryIndex = newCategories.findIndex((c) => c.id === payload.id);
        const payloadToAdd = cloneDeep(payload);
        payloadToAdd.headers.map(
            (header) =>
                (header.subcategories = header.subcategories.filter(
                    (subcategory) => subcategory.selected,
                )),
        );

        payloadToAdd.headers = payloadToAdd.headers.filter(
            (header) => header.subcategories.length > 0,
        );

        if (alreadySelectedCategoryIndex > -1) {
            payloadToAdd.headers.map((header) => {
                const payloadToAddHeaderIndex = newCategories[
                    alreadySelectedCategoryIndex
                ].headers.findIndex((h) => h.id === header.id);

                if (payloadToAddHeaderIndex === -1) {
                    newCategories[alreadySelectedCategoryIndex].headers.push(header);
                } else {
                    const payloadToAddHeaderSubcategories =
                        newCategories[alreadySelectedCategoryIndex].headers[payloadToAddHeaderIndex]
                            .subcategories;

                    newCategories[alreadySelectedCategoryIndex].headers[
                        payloadToAddHeaderIndex
                    ].subcategories = newCategories[alreadySelectedCategoryIndex].headers[
                        payloadToAddHeaderIndex
                    ].subcategories.concat(payloadToAddHeaderSubcategories);
                }
            });
        } else {
            newCategories.push({ ...payloadToAdd });
        }

        yield put({
            type: SET_SELECTED_PRODUCT_CATEGORY_SUCCESS,
            payload: newCategories,
        });
    });
}

function* deleteSelectedProductCategorySaga({ payload }) {
    yield genericSagaHandler(DELETE_SELECTED_PRODUCT_CATEGORY_PROCESS, function* () {
        console.log('delete');
        const { categoryIndex, headerIndex, subcategoryIndex } = payload;
        const productToEdit = yield select(({ EditProductReducer }) => {
            return EditProductReducer.productToEdit;
        });
        const newProductCategories = update(productToEdit, {
            mapping: {
                categories: {
                    [categoryIndex]: {
                        headers: {
                            [headerIndex]: {
                                subcategories: {
                                    $splice: [[subcategoryIndex, 1]],
                                },
                            },
                        },
                    },
                },
            },
        });
        yield put({
            type: SET_SELECTED_PRODUCT_CATEGORY_SUCCESS,
            payload: newProductCategories.mapping.tags,
        });
    });
}

function* dropItemToSectionSaga({ payload }) {
    yield genericSagaHandler(DROP_PRODUCT_TO_SECTION_PROCESS, function* () {
        const { item, sectionId } = payload;
        yield put({
            type: SET_PRODUCT_TO_EDIT_START,
            // eslint-disable-next-line react/prop-types
            payload: item._id,
        });
        // yield put({
        //     type: SET_PRODUCT_SECTIONS_START,
        // });
        yield put({
            type: UPDATE_PRODUCT_SECTION_START,
            payload: { index: item.sectionsFoundIn.length, value: sectionId },
        });
        yield put({
            type: UPDATE_PRODUCTS_TO_EDIT_START,
            payload: {
                item,
                updateSections: {
                    sectionId,
                    action: 'ADD',
                },
            },
        });
        yield put({
            type: SET_BUSINESS_FORM_DATA_START,
            payload: {
                hasUnsavedChanges: true,
            },
        });
    });
}

function* removeItemFromSectionSaga({ payload }) {
    const { item, sectionId } = payload;
    const { _id: itemId } = item;
    yield genericSagaHandler(
        REMOVE_PRODUCT_FROM_SECTION_PROCESS,
        function* removeItemFromSection() {
            yield put({
                type: SET_PRODUCT_TO_EDIT_START,
                payload: itemId,
            });
            yield put({
                type: UNSET_PRODUCT_SECTIONS_START,
                payload: sectionId,
            });
            yield put({
                type: UPDATE_PRODUCTS_TO_EDIT_START,
                payload: {
                    item,
                    updateSections: {
                        sectionId,
                        action: 'REMOVE',
                    },
                },
            });
            yield put({ type: SET_BUSINESS_FORM_DATA_START, payload: { hasUnsavedChanges: true } });
        },
    );
}

function* addEditedProductSaga({ payload }) {
    yield genericSagaHandler(UPDATE_PRODUCTS_TO_EDIT_PROCESS, function* () {
        const { item, updateSections } = payload;
        const { sectionId, action } = updateSections;
        const sections = yield select(({ SectionReducer }) => SectionReducer.sections);
        const sectionTarget = sections.find((s) => s._id === sectionId);
        const newItem = cloneDeep(item);

        if (action === 'ADD') {
            newItem.sectionsFoundIn.push(sectionTarget);
        } else if (action === 'REMOVE') {
            yield put({
                type: DELETE_PRODUCT_FROM_SECTION_START,
                payload: item._id,
            });
            remove(newItem.sectionsFoundIn, sectionTarget);
        }
        yield put({
            type: UPDATE_PRODUCTS_TO_EDIT_SUCCESS,
            payload: { item: newItem },
        });
    });
}

export function* saveProductsBulkSaga() {
    yield genericSagaHandler(SAVE_PRODUCTS_BULK_PROCESS, function* () {
        const businessId = yield select(businessIdSelector);
        const originalSections = yield select(
            ({ SectionReducer }) => SectionReducer.originalSections,
        );
        const sections = yield select(({ SectionReducer }) => SectionReducer.sections);
        if (JSON.stringify(originalSections) !== JSON.stringify(sections)) {
            try {
                yield PutBusinessSections({ entityId: businessId, sections });
            } catch (e) {
                // yield call(getSectionsSaga, { payload: { entityId: businessId } });
            }
        }

        const productSections = yield select(
            ({ SectionReducer }) => SectionReducer.sectionProducts,
        );
        const originalProductSections = yield select(
            ({ SectionReducer }) => SectionReducer.originalSectionProducts,
        );

        const sectionNamesToUpdate = [];

        for (const key in productSections) {
            const productSection = productSections[key];
            const originalProductSection = originalProductSections[key];
            if (JSON.stringify(productSection) !== JSON.stringify(originalProductSection)) {
                const section = sections.find(
                    (s) => productsAndServicesUtils.getSectionKey(s.name) === key,
                );
                if (section) {
                    sectionNamesToUpdate.push(section.name);
                    yield PutBusinessSectionItems({
                        entityId: businessId,
                        sectionId: section?._id,
                        items: productSection.map((p) => p._id),
                        active: true,
                    });
                }
            }
        }

        yield call(getSectionsSaga, { payload: { entityId: businessId } });

        for (let i = 0; i < sectionNamesToUpdate.length; i++) {
            const sectionName = sectionNamesToUpdate[i];
            yield call(getSectionProductsSaga, {
                payload: { businessId, selectedSection: sectionName },
            });
        }

        /** hasUnsavedChanges
         * Set to false, all unsaved changes have been saved
         */
        yield put({
            type: SET_BUSINESS_FORM_DATA_START,
            payload: { hasUnsavedChanges: false },
        });
    });
}

export default function* EditProductSagas() {
    yield takeEvery(SET_PRODUCT_TO_EDIT_START, setProductToEditSaga);
    yield takeEvery(SET_PRODUCT_SECTIONS_START, setProductSectionsSaga);
    yield takeEvery(UNSET_PRODUCT_SECTIONS_START, unsetProductSectionsSaga);
    yield takeEvery(UPDATE_PRODUCT_SECTION_START, updateProductSectionSaga);
    yield takeEvery(ADD_PRODUCT_SUBCATEGORY_START, addSubCategorySaga);
    yield takeEvery(DELETE_PRODUCT_SUBCATEGORY_START, deleteProductSubcategorySaga);
    yield takeEvery(ADD_PRODUCT_CATEGORY_START, addProductCategorySaga);
    yield takeEvery(DELETE_PRODUCT_CATEGORY_START, deleteProductCategorySaga);
    yield takeEvery(SET_PRODUCT_CATEGORY_START, setProductCategorySaga);
    yield takeEvery(POST_SAVE_PRODUCT_START, postSaveProductSaga);
    yield takeEvery(SET_PRODUCT_PHOTO_START, setProductPhotoSaga);
    yield takeEvery(DELETE_PRODUCT_PHOTO_START, deleteProductPhotoSaga);

    yield takeEvery(ADD_PRODUCT_START, addProductSaga);

    // yield takeEvery(UPDATE_SECTION_PRODUCTS_ORDER_START, updateSectionProductsOrder);

    yield takeEvery(SET_SELECTED_PRODUCT_CATEGORY_START, setSelectedProductCategorySaga);
    yield takeEvery(DELETE_SELECTED_PRODUCT_CATEGORY_START, deleteSelectedProductCategorySaga);

    yield takeEvery(UPDATE_PRODUCTS_TO_EDIT_START, addEditedProductSaga);
    yield takeEvery(SAVE_PRODUCTS_BULK_START, saveProductsBulkSaga);

    yield takeEvery(DROP_PRODUCT_TO_SECTION_START, dropItemToSectionSaga);
    yield takeEvery(REMOVE_PRODUCT_FROM_SECTION_START, removeItemFromSectionSaga);
}
