import {
  Button,
  Chip,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import ProductCategoriesFormModal from "./ProductCategoriesFormModal";
import { useDispatch, useSelector } from "react-redux";
import {
  DELETE_SELECTED_PRODUCT_CATEGORY_START,
  SET_SELECTED_PRODUCT_CATEGORY_START,
} from "stores/redux/actions/EditProductActions";

const ProductCategoriesForm = () => {
  const dispatch = useDispatch();
  const [showCategoryPickerModal, setShowCategoryPickerModal] =
    React.useState(false);

  const { mapping } = useSelector(
    ({ EditProductReducer }) => EditProductReducer.productToEdit
  );

  const { categories } = mapping;
  
  return (
    <Grid container item xs={12}>
      <Typography variant="h4">Categories</Typography>
      <Typography variant="body2">
        Select your category and filters to help users find your business. Pick
        a category that best describes your business, you can change this later
        too. Feel free to save at any point and come back later.
      </Typography>
      <Button
        onClick={() => {
          setShowCategoryPickerModal(true);
        }}
      >
        Add category
      </Button>
      <ProductCategoriesFormModal
        open={showCategoryPickerModal}
        onClose={() => setShowCategoryPickerModal(false)}
        onSubmit={(selectedCategory) => {
          console.log(mapping, selectedCategory);
          dispatch({
            type: SET_SELECTED_PRODUCT_CATEGORY_START,
            payload: selectedCategory,
          });
        }}
      />
      {/* <Grid item container xs={12}>
        {categories.map((category) => {
          return (
            <div>
              <Typography variant="h5">{category.name}</Typography>
              {category.headers.map((header) => {
                return (
                  <div>
                    {header.subcategories.map((subcategory) => {
                      return (
                        <div>
                          <Typography variant="h6">
                            sdd{subcategory.name}
                          </Typography>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          );
        })}
      </Grid> */}
      <Grid container item xs={12}>
        <List>
          {categories?.map((category, categoryIndex) => {
            return (
              <ListItem key={`category_${category.id}`}>
                <Grid
                  item
                  container
                  xs={12}
                  key={`category_to_save_${category.id}`}
                  sx={{ marginTop: "10px" }}
                >
                  <Typography variant="h5" color="primary">
                    {category.name}
                  </Typography>

                  {category?.headers?.map((header, headerIndex) => {
                    const hasAtLeastOneSubcatSelected =
                      header.subcategories.find((subCat) => subCat.selected);
                    if (!hasAtLeastOneSubcatSelected) {
                      return null;
                    }
                    return (
                      <ListItem divider key={`header_${header.id}`} xs={12}>
                        <ListItemAvatar>{header.name}</ListItemAvatar>
                        <ListItemText>
                          {header?.subcategories
                            ?.filter((sc) => sc.selected)
                            ?.map((subcategory, subcategoryIndex) => (
                              <Chip
                                key={`filter_chip_${subcategory.id}`}
                                color={"primary"}
                                sx={{
                                  marginRight: "12px",
                                }}
                                label={subcategory.name}
                                onDelete={() => {
                                  dispatch({
                                    type: DELETE_SELECTED_PRODUCT_CATEGORY_START,
                                    payload: {
                                      categoryIndex,
                                      headerIndex,
                                      subcategoryIndex,
                                    },
                                  });
                                  // console.log(
                                  //   "delete",
                                  //   subcategory,
                                  //   category,
                                  //   header
                                  // );
                                  // const newSelectedCategories = [
                                  //   ...categories,
                                  // ];
                                  // const categoryIndex =
                                  //   selectedCategories.findIndex(
                                  //     (sc) => sc.id === category.id
                                  //   );
                                  // const headerIndex = selectedCategories[
                                  //   categoryIndex
                                  // ].headers.findIndex(
                                  //   (h) => h.id === header.id
                                  // );
                                  // const subcategoryIndex = selectedCategories[
                                  //   categoryIndex
                                  // ].headers[
                                  //   headerIndex
                                  // ].subcategories.findIndex(
                                  //   (sc) => sc.id === subcategory.id
                                  // );
                                  // newSelectedCategories[categoryIndex].headers[
                                  //   headerIndex
                                  // ].subcategories[
                                  //   subcategoryIndex
                                  // ].selected = false;
                                  // dispatch({
                                  //   type: SET_BUSINESS_FORM_DATA_START,
                                  //   payload: {
                                  //     selectedCategories: newSelectedCategories,
                                  //   },
                                  // });
                                  // setSelectedCategories(
                                  //   newSelectedCategories
                                  // );
                                  // const newFiltersToSave = [...filtersToSave];
                                  // // const subCatToDeleteIndex = newFiltersToSave[
                                  // //   categoryIndex
                                  // // ].headers[headerIndex].subcategories.findIndex(
                                  // //   (item) => item.id === subcategory.id
                                  // // );
                                  // newFiltersToSave[categoryIndex].headers[
                                  //   headerIndex
                                  // ].subcategories.splice(subcategoryIndex, 1);
                                  // setFiltersToSave(newFiltersToSave);
                                }}
                              />
                            ))}
                        </ListItemText>
                      </ListItem>
                    );
                  })}
                </Grid>
              </ListItem>
            );
          })}
        </List>
      </Grid>
    </Grid>
  );
};
export default ProductCategoriesForm;
