import { GetBusinessMapping, getEntityTopCategory, GetTag } from 'api';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import {
    CLEAR_BUSINESS_CATEGORIES,
    GET_BUSINESS_CATEGORIES_PROCESS,
    GET_BUSINESS_CATEGORIES_STACK_NEXT_PROCESS,
    GET_BUSINESS_CATEGORIES_STACK_NEXT_START,
    GET_BUSINESS_CATEGORIES_STACK_RESET_PROCESS,
    GET_BUSINESS_CATEGORIES_STACK_RESET_START,
    GET_BUSINESS_CATEGORIES_STACK_SUCCESS,
    GET_BUSINESS_CATEGORIES_START,
    GET_BUSINESS_CATEGORIES_SUCCESS,
    GET_BUSINESS_SUBCATEGORIES_PROCESS,
    GET_BUSINESS_SUBCATEGORIES_START,
    GET_BUSINESS_SUBCATEGORIES_SUCCESS,
    GET_BUSINESS_SUBCATEGORY_FILTERS_PROCESS,
    GET_BUSINESS_SUBCATEGORY_FILTERS_START,
    GET_BUSINESS_SUBCATEGORY_FILTERS_SUCCESS,
    GET_DESTINATION_CATEGORIES_START,
    GET_DESTINATION_CATEGORIES_SUCCESS,
    GET_INTERESTS_CATEGORY_PROCESS,
    GET_INTERESTS_CATEGORY_START,
    GET_INTERESTS_CATEGORY_SUCCESS,
    GET_ITEM_CATEGORIES_PROCESS,
    GET_ITEM_CATEGORIES_START,
    GET_ITEM_CATEGORIES_SUCCESS,
    GET_SEARCH_CATEGORIES_PROCESS,
    GET_SEARCH_CATEGORIES_START,
    GET_TOP_LEVEL_GROUPS_PROCESS,
    GET_TOP_LEVEL_GROUPS_START,
    GET_TOP_LEVEL_GROUPS_SUCCESS,
    GET_BUSINESS_CATEGORIES_STACK_INITIALIZE_START,
    GET_BUSINESS_CATEGORIES_STACK_INITIALIZE_PROCESS,
    GET_BUSINESS_CATEGORIES_STACK_INITIALIZE_SUCCESS
} from '../actions/CategoriesActions';
import genericSagaHandler from './CommonSaga';

function* getCategoriesSaga() {
    yield genericSagaHandler(GET_ITEM_CATEGORIES_PROCESS, function* () {
        const type = yield select(
            ({ EditProductReducer }) => EditProductReducer.productToEdit.type,
        );

        const topLevelGroups = yield select(
            ({ CategoriesReducer }) => CategoriesReducer.topLevelGroups,
        );
        const productGroup = topLevelGroups.find(
            (group) => group.name.toLowerCase() === type.toLowerCase(),
        );
        if (productGroup) {
            const businessCategories = yield GetBusinessMapping(productGroup._id);
            const productCategoriesWithCrumbs = businessCategories.map((category) => ({
                ...category,
                id: category._id,
                crumbs: [...(category.crumbs || []), productGroup],
            }));

            yield put({
                type: GET_ITEM_CATEGORIES_SUCCESS,
                payload: productCategoriesWithCrumbs,
            });
            yield put({
                type: GET_BUSINESS_CATEGORIES_STACK_SUCCESS,
                payload: [
                    { categoryId: productGroup._id, categories: productCategoriesWithCrumbs },
                ],
            });
        }
    });
}
function* fetchFiltersForHeader(headerId, headerName = undefined) {
    const filters = yield GetBusinessMapping(headerId);
    return { headerId, headerName, filters };
}

function* getBusinessSubcategoryFiltersSaga(action) {
    yield genericSagaHandler(GET_BUSINESS_SUBCATEGORY_FILTERS_PROCESS, function* () {
        const { subcategoryId, categoryId } = action.payload;
        const state = yield select();

        if (subcategoryId && categoryId) {
            yield* handleSubcategoryFilters(subcategoryId, categoryId, state);
        } else if (categoryId && subcategoryId === '') {
            const cachedCategoryFilters =
                state.CategoriesReducer?.businessSubcategoryFilters?.[categoryId];
            if (!cachedCategoryFilters) {
                const businessCategoryHeaders = yield GetBusinessMapping(categoryId);
                const currentCategoryInBusiness = state.CategoriesReducer?.businessCategories?.find(
                    (category) => category._id === categoryId,
                );
                const currentCategoryInItems = state.CategoriesReducer?.itemCategories?.find(
                    (category) => category._id === categoryId,
                );
                const currentCategoryInInterests = state.CategoriesReducer?.interestsCategory?.find(
                    (category) => category._id === categoryId,
                );
                const currentCategory =
                    currentCategoryInBusiness ||
                    currentCategoryInItems ||
                    currentCategoryInInterests;
                const { crumbs: currentCategoryCrumbs, ...currentCategoryWithoutCrumbs } =
                    currentCategory || {};
                const filters = yield all(
                    businessCategoryHeaders.map((header) =>
                        call(fetchFiltersForHeader, header._id),
                    ),
                );

                const data = businessCategoryHeaders.map((header) => ({
                    ...header,
                    filters: filters
                        .find((filter) => filter.headerId === header._id)
                        .filters.map((filter) => ({
                            ...filter,
                            id: filter._id,
                            crumbs: [
                                ...(currentCategoryCrumbs || []),
                                currentCategoryWithoutCrumbs,
                                header,
                            ],
                        })),
                }));

                yield put({
                    type: GET_BUSINESS_SUBCATEGORY_FILTERS_SUCCESS,
                    payload: { subcategoryId: categoryId, data },
                });
            }
        }
    });
}
function* handleSubcategoryFilters(subcategoryId, categoryId, state) {
    const cachedSubcategoriesFilters =
        state.CategoriesReducer?.businessSubcategoryFilters?.[subcategoryId];

    if (!cachedSubcategoriesFilters) {
        const businessSubcategoryHeaders = yield GetBusinessMapping(subcategoryId);
        const filters = yield all(
            businessSubcategoryHeaders.map((header) => call(fetchFiltersForHeader, header._id)),
        );

        // const currentSubcategory = state.CategoriesReducer?.businessSubcategories?.[
        //     categoryId
        // ]?.find((subcategory) => subcategory._id === subcategoryId);

        // const { crumbs: currentSubcategoryCrumbs, ...currentSubcategoryWithoutCrumbs } =
        //     currentSubcategory;

        const data = businessSubcategoryHeaders.map((header) => ({
            ...header,
            filters: filters
                .find((filter) => filter.headerId === header._id)
                .filters.map((filter) => ({
                    ...filter,
                    id: filter._id,
                })),
        }));

        yield put({
            type: GET_BUSINESS_SUBCATEGORY_FILTERS_SUCCESS,
            payload: { subcategoryId, data },
        });
    }
}
function* getBusinessSubcategoriesSaga(action) {
    yield genericSagaHandler(GET_BUSINESS_SUBCATEGORIES_PROCESS, function* () {
        const { categoryId } = action.payload;
        const state = yield select();
        const currentCategoryInBusiness = state.CategoriesReducer?.businessCategories?.find(
            (category) => category._id === categoryId,
        );
        const currentCategoryInItems = state.CategoriesReducer?.itemCategories?.find(
            (category) => category._id === categoryId,
        );
        const currentCategoryInInterests = state.CategoriesReducer?.interestsCategory?.find(
            (category) => category._id === categoryId,
        );
        const cachedSubcategories = state.CategoriesReducer?.businessSubcategories?.[categoryId];

        if (!cachedSubcategories) {
            const subcategories = yield GetBusinessMapping(categoryId);
            const isHeaderPresent = subcategories.some((item) => item.type === 'header');
            if (isHeaderPresent) {
                yield call(getBusinessSubcategoryFiltersSaga, {
                    payload: { subcategoryId: '', categoryId: categoryId },
                });
            } else {
                const currentCategory =
                    currentCategoryInBusiness ||
                    currentCategoryInItems ||
                    currentCategoryInInterests;

                const { crumbs: currentCategoryCrumbs, ...currentCategoryWithoutCrumbs } =
                    currentCategory || {};

                const data = subcategories.map((subcategory) => ({
                    ...subcategory,
                    id: subcategory._id,
                    crumbs: [
                        ...(subcategory.crumbs || []),
                        ...(currentCategoryCrumbs || []),
                        currentCategoryWithoutCrumbs,
                    ],
                }));

                yield put({
                    type: GET_BUSINESS_SUBCATEGORIES_SUCCESS,
                    payload: { categoryId, data },
                });
            }
        }
    });
}
function* getBusinessCategoriesSaga() {
    yield genericSagaHandler(GET_BUSINESS_CATEGORIES_PROCESS, function* () {
        const _id = yield select(({ BusinessFormReducer }) => BusinessFormReducer._id);
        const entityTypeId = _id.split('/')[0];
        let topLevelGroups = yield select(
            ({ CategoriesReducer }) => CategoriesReducer.topLevelGroups,
        );
        if (!topLevelGroups?.length) {
            yield call(getTopLevelGroupsSaga);
            topLevelGroups = yield select(
                ({ CategoriesReducer }) => CategoriesReducer.topLevelGroups,
            );
        }

        const [topLevelTagsAllCategories] = yield getEntityTopCategory();
        const topLevelTagId = topLevelTagsAllCategories[entityTypeId];
        const topLevelTag = GetTag(topLevelTagId);
        let businessCategories = yield GetBusinessMapping(topLevelTagId);
        const businessCategoriesWithCrumbs = businessCategories.map((category) => ({
            ...category,
            id: category._id,
            crumbs: [...(category.crumbs || []), topLevelTag],
        }));
        yield put({
            type: GET_BUSINESS_CATEGORIES_SUCCESS,
            payload: businessCategoriesWithCrumbs,
        });
    });
}
function* getCategoriesSearchSaga(actions) {
    yield genericSagaHandler(GET_SEARCH_CATEGORIES_PROCESS, function* () {
        const { topLevelGroupId } = actions.payload;

        if (!topLevelGroupId) {
            yield put({
                type: CLEAR_BUSINESS_CATEGORIES,
            });
            return;
        }

        let topLevelGroups = yield select(
            ({ CategoriesReducer }) => CategoriesReducer.topLevelGroups,
        );
        if (!topLevelGroups?.length) {
            yield call(getTopLevelGroupsSaga);
            topLevelGroups = yield select(
                ({ CategoriesReducer }) => CategoriesReducer.topLevelGroups,
            );
        }

        const categoryGroup = topLevelGroups.find((group) => group._id === topLevelGroupId);

        if (categoryGroup) {
            let businessCategories = yield GetBusinessMapping(categoryGroup._id);

            const businessCategoriesWithCrumbs = businessCategories.map((category) => ({
                ...category,
                id: category._id,
                crumbs: [...(category.crumbs || []), categoryGroup],
            }));

            yield put({
                type: GET_BUSINESS_CATEGORIES_SUCCESS,
                payload: businessCategoriesWithCrumbs,
            });
        } else {
            yield put({
                type: CLEAR_BUSINESS_CATEGORIES,
            });
        }
    });
}
function* getTopLevelGroupsSaga() {
    yield genericSagaHandler(GET_TOP_LEVEL_GROUPS_PROCESS, function* () {
        const businessCategories = yield GetBusinessMapping('ROOT');

        yield put({
            type: GET_TOP_LEVEL_GROUPS_SUCCESS,
            payload: businessCategories,
        });
    });
}

function* getInterestCategorySaga(action) {
    yield genericSagaHandler(GET_INTERESTS_CATEGORY_PROCESS, function* () {
        const { interest } = action.payload;
        let topLevelGroups = yield select(
            ({ CategoriesReducer }) => CategoriesReducer.topLevelGroups,
        );
        if (!topLevelGroups?.length) {
            yield call(getTopLevelGroupsSaga);
            topLevelGroups = yield select(
                ({ CategoriesReducer }) => CategoriesReducer.topLevelGroups,
            );
        }

        const topLevelGroupForType = topLevelGroups.find(
            (group) => group.name.toLowerCase() === 'interests',
        );

        if (!topLevelGroupForType || !topLevelGroupForType._id) {
            yield put({
                type: CLEAR_BUSINESS_CATEGORIES,
            });
        }
        const interestsCategory = yield GetBusinessMapping(topLevelGroupForType._id);

        const interestCategoryWithCrumbs = interestsCategory
            .filter((category) => category.name.toLowerCase() === interest.toLowerCase())
            .map((category) => ({
                ...category,
                id: category._id,
                crumbs: [...(category.crumbs || []), topLevelGroupForType],
            }));

        yield put({
            type: GET_INTERESTS_CATEGORY_SUCCESS,
            payload: interestCategoryWithCrumbs,
        });
    });
}

function* getDestinationCategoriesSaga(action) {
    yield genericSagaHandler(GET_INTERESTS_CATEGORY_PROCESS, function* () {
        let topLevelGroups = yield select(
            ({ CategoriesReducer }) => CategoriesReducer.topLevelGroups,
        );
        if (!topLevelGroups?.length) {
            yield call(getTopLevelGroupsSaga);
            topLevelGroups = yield select(
                ({ CategoriesReducer }) => CategoriesReducer.topLevelGroups,
            );
        }

        const topLevelGroupForType = topLevelGroups.find(
            (group) => group.name.toLowerCase() === 'destinations',
        );

        if (!topLevelGroupForType || !topLevelGroupForType._id) {
            yield put({
                type: CLEAR_BUSINESS_CATEGORIES,
            });
        }
        const destinationsCategory = yield GetBusinessMapping(topLevelGroupForType._id);

        const destinationCategoryWithCrumbs = destinationsCategory.map((category) => ({
            ...category,
            id: category._id,
            crumbs: [...(category.crumbs || []), topLevelGroupForType],
        }));

        yield put({
            type: GET_DESTINATION_CATEGORIES_SUCCESS,
            payload: destinationCategoryWithCrumbs,
        });
    });
}

function* getBusinessCategoriesStackNextSaga(action) {
    yield genericSagaHandler(GET_BUSINESS_CATEGORIES_STACK_NEXT_PROCESS, function* () {
        const { categoryId } = action.payload;
        const currentStack = yield select(
            ({ CategoriesReducer }) => CategoriesReducer.businessCategoriesStack,
        );

        const categories = yield GetBusinessMapping(categoryId);

        let stack = [...currentStack];

        const categoryAlreadyPresent = categories.find((category) => category._id === categoryId);

        // If the category is already in the stack, remove all categories after it
        if (categoryAlreadyPresent) {
            const indexToSlice = stack.indexOf(categoryAlreadyPresent);

            if (indexToSlice > -1) {
                stack = stack.slice(0, indexToSlice + 1);
            }
        }

        // check if the response is a header, if so, get all filters
        const isHeaderPresent = categories.some((item) => item.type === 'header');

        if (isHeaderPresent) {
            const filters = yield all(
                categories.map((header) =>
                    call(fetchFiltersForHeader, header._id, header?.label ?? header?.name),
                ),
            );

            yield put({
                type: GET_BUSINESS_CATEGORIES_STACK_SUCCESS,
                payload: [...stack, { categoryId, filters }],
            });
            return;
        }

        yield put({
            type: GET_BUSINESS_CATEGORIES_STACK_SUCCESS,
            payload: [...stack, { categoryId, categories }],
        });
    });
}

function* initializeBusinessCategoriesStackSaga(action) {
    yield genericSagaHandler(GET_BUSINESS_CATEGORIES_STACK_INITIALIZE_PROCESS, function* () {
        const { product } = action.payload;
        const currentStack = yield select(
            ({ CategoriesReducer }) => CategoriesReducer.businessCategoriesStack,
        );

        if (currentStack.length > 0) {
            yield call(getBusinessCategoriesStackResetSaga, {
                payload: { businessCategoriesStack: [] },
            });
        }
        
        const categoriesOnly = product?.mapping?.tags?.filter(q => q.type?.toLowerCase() !== 'filter') || [];

        const categoriesIdsToIterate = product.tagsV2Map.filter(categoryId => categoriesOnly.some(category => category.id === categoryId));

        for (let index = 0; index < categoriesIdsToIterate.length; index++) {
            const categoryId = categoriesIdsToIterate[index];

            yield call(getBusinessCategoriesStackNextSaga, {
                payload: { categoryId },
            });
        }
        yield put({
            type: GET_BUSINESS_CATEGORIES_STACK_INITIALIZE_SUCCESS
        });
    });
}

function* getBusinessCategoriesStackResetSaga(action) {
    yield genericSagaHandler(GET_BUSINESS_CATEGORIES_STACK_RESET_PROCESS, function* () {
        const { businessCategoriesStack = [] } = action.payload;

        yield put({
            type: GET_BUSINESS_CATEGORIES_STACK_SUCCESS,
            payload: businessCategoriesStack,
        });
    });
}

export default function* CategoriesSagas() {
    yield takeEvery(GET_ITEM_CATEGORIES_START, getCategoriesSaga);
    yield takeEvery(GET_BUSINESS_SUBCATEGORY_FILTERS_START, getBusinessSubcategoryFiltersSaga);
    yield takeEvery(GET_BUSINESS_SUBCATEGORIES_START, getBusinessSubcategoriesSaga);
    yield takeEvery(GET_SEARCH_CATEGORIES_START, getCategoriesSearchSaga);
    yield takeEvery(GET_BUSINESS_CATEGORIES_START, getBusinessCategoriesSaga);
    yield takeEvery(GET_TOP_LEVEL_GROUPS_START, getTopLevelGroupsSaga);
    yield takeEvery(GET_INTERESTS_CATEGORY_START, getInterestCategorySaga);
    yield takeEvery(GET_DESTINATION_CATEGORIES_START, getDestinationCategoriesSaga);
    yield takeEvery(GET_BUSINESS_CATEGORIES_STACK_NEXT_START, getBusinessCategoriesStackNextSaga);
    yield takeEvery(GET_BUSINESS_CATEGORIES_STACK_INITIALIZE_START, initializeBusinessCategoriesStackSaga);
    yield takeEvery(GET_BUSINESS_CATEGORIES_STACK_RESET_START, getBusinessCategoriesStackResetSaga);
}
