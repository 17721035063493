import { ModalContext } from 'baseline-ui/modal';
import { Overlay, OverlayContext } from 'baseline-ui/overlay';
import overlayPropTypes from 'baseline-ui/overlay/src/prop-types/overlayPropTypes';
import useIsFormDirtyFeedback from 'common/hooks/useIsFormDirtyFeedback';
import useProcessLoader from 'common/hooks/useProcessLoader';
import { Form, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import ModalUnsavedChanges from 'screens/BusinessForm/ModalUnsavedChanges';
import { GET_BUSINESS_CATEGORIES_STACK_INITIALIZE_START, GET_BUSINESS_CATEGORIES_STACK_RESET_START, GET_ITEM_CATEGORIES_START } from 'stores/redux/actions/CategoriesActions';
import {
    POST_SAVE_PRODUCT_PROCESS,
    POST_SAVE_PRODUCT_START,
    SET_PRODUCT_TO_EDIT_PROPERTY_START,
} from 'stores/redux/actions/EditProductActions';
import { productToEditSelector } from 'stores/redux/selectors/productSelectors';
import AddProductStepper from './AddProductStepper';
import ModalEditProductCategories from './ModalEditProductCategories';
import ModalEditProductInformation from './ModalEditProductInformation';
import ModalEditProductPhotos from './ModalEditProductPhotos';
import ModalSelectSections from './ModalSelectSections';

const ModalEditProductForm = ({ title, footerActions, onRequestClose, ...props }) => {
    const [currentStep, setStep] = useState(0);
    const intl = useIntl();
    const { hideOverlay } = useContext(OverlayContext);
    const { showModal, hideModal } = useContext(ModalContext);
    const product = useSelector(productToEditSelector);
    const { values, dirty, handleSubmit, isValid } = useFormikContext();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({
            type: SET_PRODUCT_TO_EDIT_PROPERTY_START,
            payload: values,
        });

        if (!product?.mapping?.tags?.length) {
            dispatch({
                type: GET_ITEM_CATEGORIES_START,
            });
            return;
        }

        dispatch({
            type: GET_BUSINESS_CATEGORIES_STACK_INITIALIZE_START,
            payload: {
                product,
            }
        })
    }, [values]);

    const handleSectionChange = (currentSectionId, currentSectionIndex) => {
        setStep(currentSectionIndex);
    };

    useIsFormDirtyFeedback({
        isDirty: dirty,
        dependencies: [values],
        processName: SET_PRODUCT_TO_EDIT_PROPERTY_START,
    });

    const isLoading = useProcessLoader({
        processNames: [POST_SAVE_PRODUCT_PROCESS],
        withOverlay: true,
    });

    if (!product) {
        return null;
    }

    const { hasUnsavedChanges } = product;

    const handleOnClickSaveCallback = () => {
        if (hasUnsavedChanges) {
            return showModal(ModalUnsavedChanges, {
                onSaveCallback: () => {
                    if (isValid) {
                        dispatch({ type: POST_SAVE_PRODUCT_START });
                    } else {
                        hideModal();
                        handleSubmit();
                    }
                },
                onSaveCompleteCallback: () => {
                    hideOverlay();
                },
                processNames: [POST_SAVE_PRODUCT_PROCESS],
                onLeaveCallback: () => {
                    hideOverlay();
                },
            });
        }

        return hideOverlay();
    };

    return (
        <Overlay
            title={title}
            size="lg"
            handleOnDismiss={handleOnClickSaveCallback}
            footerActions={[
                {
                    label: intl.formatMessage({
                        id: 'addBusiness.modal.editProduct.footerActions.button.cancel.label',
                    }),
                    btnType: 'link',
                    skin: 'muted',
                    onClick: () => {
                        handleOnClickSaveCallback();
                    },
                    type: 'button',
                },
                {
                    label: intl.formatMessage({
                        id: 'addBusiness.modal.editProduct.footerActions.button.confirm.label',
                    }),
                    skin: 'secondary',
                    onClick: () => {
                        handleSubmit();
                    },
                    loading: isLoading,
                    loadingMessage: intl.formatMessage({
                        id: 'addBusiness.modal.editProduct.footerActions.button.confirming.label',
                    }),
                    type: 'submit',
                },
            ]}
            {...props}
        >
            <AddProductStepper
                onStepClick={(currentSectionId, currentSectionIndex) =>
                    handleSectionChange(currentSectionId, currentSectionIndex)
                }
                currentStep={currentStep}
            />
            <Form noValidate>
                {currentStep === 0 && <ModalEditProductInformation />}
                {currentStep === 1 && <ModalEditProductPhotos />}
                {currentStep === 2 && <ModalEditProductCategories />}
            </Form>
            {currentStep === 3 && <ModalSelectSections />}
        </Overlay>
    );
};

ModalEditProductForm.propTypes = {
    title: PropTypes.string,
    onRequestClose: PropTypes.func.isRequired,
    footerActions: overlayPropTypes.overlayFooterActions,
};

ModalEditProductForm.defaultProps = {
    title: undefined,
    footerActions: undefined,
};

export default ModalEditProductForm;
