import CloseIcon from '@mui/icons-material/Close';
import { Box, Checkbox, Chip, ListItemText, MenuItem } from '@mui/material';
import { H3 } from 'baseline-ui/typography';
import CategoriesSelect from 'components/Categories/CategoriesSelect';
import { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { GET_BUSINESS_CATEGORIES_STACK_NEXT_START, GET_BUSINESS_CATEGORIES_STACK_RESET_START } from 'stores/redux/actions/CategoriesActions';
import './ProductCategoriesFormFields.css'; // Import the CSS file for styling

const ProductCategoriesFormFields = ({ selectedFilters = [], onSelectedFiltersChange, onSubmit }) => {
    const dispatch = useDispatch();
    const formTouched = useRef(false);
    const categoriesStack = useSelector(
        ({ CategoriesReducer }) => CategoriesReducer.businessCategoriesStack,
    );

    const markAsTouched = () => {
        formTouched.current = true;
    };

    const getNext = (categoryId, index) => {
        const existingStackChangedValue = categoriesStack[index + 1] && categoriesStack[index + 1]?.categoryId !== categoryId;
        const noValuesChanged = categoriesStack[index + 1]?.categoryId === categoryId

        if (noValuesChanged) {
            return;
        }

        if (!categoryId || existingStackChangedValue) {
            dispatch({
                type: GET_BUSINESS_CATEGORIES_STACK_RESET_START,
                payload: { businessCategoriesStack: categoriesStack?.slice(0, index + 1) },
            })
            onSelectedFiltersChange([]);
        }

        if (!categoryId) {
            return;
        }

        dispatch({
            type: GET_BUSINESS_CATEGORIES_STACK_NEXT_START,
            payload: { categoryId },
        });
    };

    const renderCategories = ({ categories }, index) => {
        const value = categoriesStack[index + 1]?.categoryId;

        return (
            <Box className="category-select">
                <H3 color="secondary">
                    <FormattedMessage id="addBusiness.page.sections.categories.list.category.label" />
                </H3>
                <CategoriesSelect
                    name="category"
                    fullWidth
                    margin={'8px 0'}
                    value={value}
                    defaultValue={value}
                    onChange={(e) => {
                        markAsTouched();
                        getNext(e.target.value, index)
                    }}
                >
                    <MenuItem value="">
                        <em>Select a category</em>
                    </MenuItem>
                    {categories?.map((category) => (
                        <MenuItem key={category._id} value={category._id}>
                            {category.name}
                        </MenuItem>
                    ))}
                </CategoriesSelect>
            </Box>
        );
    };

    const renderSubcategories = ({ categories }, index) => {
        const value = categoriesStack[index + 1]?.categoryId;
        return (
            <Box className="subcategory-select">
                <H3 color="secondary">
                    <FormattedMessage id="addBusiness.page.sections.categories.subcategory.label" />
                </H3>
                <CategoriesSelect
                    name="subcategory"
                    fullWidth
                    margin={'8px 0'}
                    value={value}
                    defaultValue={value}
                    onChange={(e) => {
                        markAsTouched();
                        getNext(e.target.value, index)
                    }}
                >
                    <MenuItem value="">
                        <em>Select a subcategory</em>
                    </MenuItem>
                    {categories.map((subcategory) => (
                        <MenuItem key={subcategory._id} value={subcategory._id}>
                            {subcategory.name}
                        </MenuItem>
                    ))}
                </CategoriesSelect>
            </Box>
        );
    };

    const handleRemoveFilter = (filterId) => {
        markAsTouched();
        onSelectedFiltersChange(selectedFilters.filter((id) => id !== filterId));
    };

    const handleCheckboxToggle = (filterId) => {
        markAsTouched();
        const isChecked = selectedFilters.includes(filterId);

        if (isChecked) {
            handleRemoveFilter(filterId)
        } else {
            onSelectedFiltersChange([...selectedFilters, filterId]);
        }
    };

    const renderFilters = ({ filters: headers }) => {
        if (!headers?.length) {
            return null;
        }

        return (
            <Box className="filters-select">
                <H3 color="secondary">
                    <FormattedMessage id="addBusiness.page.sections.categories.filters.label" />
                </H3>
                <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                    {headers.map((header, index) => {
                        return (
                            header.filters.length > 0 && (
                                <Box gridColumn={{ xs: 'span 12', md: 'span 6' }} key={header._id}>
                                    <CategoriesSelect
                                        name={`filter[${index}]`}
                                        fullWidth
                                        displayEmpty
                                        value={selectedFilters}
                                        multiple
                                        renderValue={(selected) => {
                                            return (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        flexDirection: 'column', // Display items vertically
                                                        gap: 0.5,
                                                    }}
                                                >
                                                    {selected.length === 0 && (
                                                        <span>
                                                            <em>{header?.label ?? header?.name}</em>
                                                        </span>
                                                    )}

                                                    {Array.isArray(selected)
                                                        ? selected.map((value) => {
                                                            const selectedItem = header?.filters.find(q => q._id === value);
                                                            return (
                                                                selectedItem && (
                                                                    <Chip
                                                                        key={value}
                                                                        label={selectedItem.label}
                                                                        onMouseDown={(event) => {
                                                                            event.stopPropagation();
                                                                        }}
                                                                        onDelete={(event) => {
                                                                            event.preventDefault();
                                                                            event.stopPropagation();
                                                                            handleRemoveFilter(
                                                                                value
                                                                            );
                                                                        }}
                                                                        deleteIcon={<CloseIcon />}
                                                                    />
                                                                )
                                                            );
                                                        })
                                                        : null}
                                                </Box>
                                            );
                                        }}
                                    >
                                        <MenuItem value="">
                                            <em>Select {header.headerName}</em>
                                        </MenuItem>
                                        {header.filters.map((filter) => {
                                            const isChecked = selectedFilters?.includes(filter._id);

                                            return (
                                                <MenuItem
                                                    key={filter._id}
                                                    onClick={() => handleCheckboxToggle(filter._id)}
                                                >
                                                    <Checkbox checked={isChecked} />
                                                    <ListItemText primary={filter.name} />
                                                </MenuItem>
                                            );
                                        })}
                                    </CategoriesSelect>
                                </Box>
                            )
                        );
                    })}
                </Box>
            </Box>
        );
    };

    const renderCategoriesByType = () => {
        return categoriesStack.map((categoryStackItem, index) => {
            const isHeaderPresent = !!categoryStackItem?.filters;
            const parent = categoryStackItem?.categories?.[0]?.parent;

            if (isHeaderPresent) {
                return renderFilters(categoryStackItem);
            }

            switch (parent?.type) {
                // The first category in the form has this type
                case 'group':
                    return renderCategories(categoryStackItem, index);
                // The first subcategory in the form has this type
                case 'category':
                    return renderSubcategories(categoryStackItem, index);
                // The other subcategories in the form have this type
                case 'subcategory':
                    return renderSubcategories(categoryStackItem, index);
                default:
                    return null;
            }
        });
    };

    useEffect(() => {
        if (!formTouched.current) {
            return;
        }

        const idMap = new Set();

        categoriesStack.forEach((categoryStack) => {
            if (idMap.has(categoryStack.categoryId)) {
                return;
            }
            idMap.add(categoryStack.categoryId);
        })
        selectedFilters.forEach((selectedFilter) => {
            if (idMap.has(selectedFilter)) {
                return;
            }
            idMap.add(selectedFilter);
        })

        onSubmit([...idMap].map(id => ({ id })));
    }, [categoriesStack, selectedFilters])

    return (
        <>
            <Box mt={2} mb={3}>
                <Box className="categories-row">
                    {renderCategoriesByType()}
                </Box>
            </Box>
        </>
    );
};

export default ProductCategoriesFormFields;
