/* eslint-disable camelcase */
import {
    // Image editor
    openEditor,
    createDefaultImageReader,
    createDefaultImageWriter,

    // The method used to register the plugins
    setPlugins,

    // The plugins we want to use
    plugin_crop,
    plugin_finetune,
    plugin_annotate,

    // The main UI and plugin locale objects
    locale_en_gb,
    plugin_crop_locale_en_gb,
    plugin_finetune_locale_en_gb,
    plugin_annotate_locale_en_gb,

    // Because we use the annotate plugin we also need
    // to import the markup editor locale and the shape preprocessor
    markup_editor_locale_en_gb,
    createDefaultShapePreprocessor,

    // Import the default properties
    markup_editor_defaults,
    plugin_finetune_defaults,
    blobToFile,
} from '@pqina/pintura';
import arrayMove from 'array-move';
import { PHOTO_STATE_DELETED } from 'common/consts/consts';
import heic2any from 'heic2any';

const fileUploaderUtils = {
    setPlugins: () => {
        setPlugins(plugin_crop, plugin_finetune, plugin_annotate);
    },
    editImage: (image, doneCallbackFunc) => {
        const imageFile = image.pintura ? image.pintura.file : image;
        const originalImageState = image.pintura ? image.pintura.data : {};
        let imageOriginalSize = null;

        const editor = openEditor({
            src: imageFile,
            originalImageState,
            imageReader: createDefaultImageReader({
                preprocessImageFile: async (file, options, onprogress) => {
                    // If is not of type HEIC we skip the file
                    if (!/heic/.test(file.type)) return file;

                    // Let's turn the HEIC image into JPEG so the browser can read it
                    const blob = await heic2any({
                        blob: file,
                        toType: 'image/jpeg',
                        quality: 0.5,
                        targetSize: {
                            width: 1170,
                            height: 1170,
                            fit: 'cover',
                        },
                    });

                    // The editor expects a File so let's convert our Blob
                    return blobToFile(blob, file.name);
                },
            }),
            imageWriter: createDefaultImageWriter({
                quality: 0.5,
                mimeType: 'image/jpeg',
                targetSize: {
                    width: 1170,
                    height: 1170,
                    fit: 'cover',
                },
            }),

            // Set Markup Editor defaults
            ...markup_editor_defaults,

            // Set Finetune plugin defaults
            ...plugin_finetune_defaults,

            // This handles complex shapes like arrows / frames
            shapePreprocessor: createDefaultShapePreprocessor(),

            // This will set a square crop aspect ratio
            imageCropAspectRatio: 1,
            // Set locale
            locale: {
                ...locale_en_gb,
                ...plugin_crop_locale_en_gb,
                ...plugin_finetune_locale_en_gb,
                ...plugin_annotate_locale_en_gb,
                ...markup_editor_locale_en_gb,
            },
        });

        editor.on('close', () => {
            // the user cancelled editing the image
        });

        editor.on('load', (e) => {
            imageOriginalSize = e.size;
        });

        editor.on('process', ({ dest, imageState, src }) => {
            const originalImage = imageFile;

            Object.assign(dest, {
                pintura: { file: imageFile, data: imageState },
            });

            const { width, height } = imageState?.crop;
            const useCropped = width + height !== imageOriginalSize.width + imageOriginalSize.height;
            doneCallbackFunc({ originalImage: originalImage, updatedImage: dest, useCropped: useCropped });
        });
    },
    editImageDoneCallback: ({ photos, setPhotos, index, output }) => {
        const updatedPhotos = [...photos];

        if (output.useCropped) {
            updatedPhotos[index] = {
                originalImage: output.originalImage,
                updatedImage: output.updatedImage,
                useCropped: true,
                name: photos[index].name,
                state: 'updated',
            };
        } else {
            updatedPhotos[index] = output.updatedImage;
        }

        Object.assign(output.updatedImage, {
            preview: URL.createObjectURL(output.updatedImage),
            state: 'updated',
        });
        // update view
        setPhotos(updatedPhotos);
    },
    restoreOriginal: async (photos, setPhotos, index) => {
        const updatedPhotos = [...photos];
        let photo = updatedPhotos[index];

        if (photo) {
            updatedPhotos[index] = {
                ...photo,
                updatedImage: null,
                useCropped: false,
                state: 'restored',
            };

            photo = updatedPhotos[index];
            // revoke preview URL for cropped image
            if (photo.updatedImage?.preview) {
                URL.revokeObjectURL(photo.updatedImage.preview);
            }

            let originalImageFile = null;
            if (photo.originalImage){
                const response = await fetch(photo.originalImage);
                const blob = await response.blob();
                
                originalImageFile = new File([blob], photo.name, {
                    type: blob.type,
                });
            }


            Object.assign(photo, {
                preview: URL.createObjectURL(originalImageFile),
                state: 'restored',
                originalImageFile: originalImageFile,
            });

            // update view
            setPhotos(updatedPhotos);
        }
    },
    handleFileUpload: (files, photos, setPhotos) => {
        const file = files instanceof FileList ? files[0] : files;

        Object.assign(file, {
            preview: URL.createObjectURL(file),
        });

        fileUploaderUtils.editImage(file, (output) => {
            const updatedPhotos = [...photos];
            const { originalImage, updatedImage } = output;
            // add new image object containing both original and cropped images
            if(updatedImage) {
                originalImage.updatedImage = updatedImage;
                originalImage.useCropped = true;
            }
            updatedPhotos.push(originalImage);
            // revoke preview URL for old image
            if (file.preview) URL.revokeObjectURL(file.preview);
            // set new preview URL

            updatedImage &&
                Object.assign(originalImage.updatedImage, {
                    preview: URL.createObjectURL(updatedImage),
                    state: 'updated',
                });
            
            Object.assign(originalImage, {
                preview: URL.createObjectURL(originalImage),
                state: 'new',
            });
        

            // update view
            setPhotos(updatedPhotos);
        });
    },
    onSortEnd: ({ photos, setPhotos, oldIndex, newIndex }) => {
        setPhotos(arrayMove(photos, oldIndex, newIndex));
    },
    currentPhotosCount: (photos) => {
        if (!photos) {
            return 0;
        }
        return photos.filter((photo) => photo.state !== PHOTO_STATE_DELETED).length;
    },
    currentPhotoList: (photos) => photos?.filter((photo) => photo.state !== 'deleted')
};

export default fileUploaderUtils;